//Transferance.js
import React from "react";
import { Link } from "react-router-dom"; // Removed unused imports

function Transferance() {

  return (
    <div className="App text-center">
    <header className="relative flex flex-col justify-center items-center h-screen overflow-hidden">
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
      >
        <source src="/Transferance_gameplay.mp4" type="video/mp4" />
      </video>

      <div className="relative z-2 text-white p-5 md:p-10">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">Transferance</h1>
        <p className="text-xl md:text-2xl lg:text-3xl mt-2">A Second Year Game Development Project</p>
      </div>
    </header>
    <main className="pt-16">

      </main>

      <body>
        <div>
        </div>
      </body>

    </div>
  );
}

export default Transferance;
