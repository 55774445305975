import React from "react";

function Ballelujah() {
  return (
    <div className="App">
      <header className="relative flex flex-col justify-center items-center h-screen overflow-hidden">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
        >
          <source src="/Ball_Gamplay.mp4" type="video/mp4" />
        </video>

        <div className="relative z-10 text-white p-5 md:p-10">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center">Ballelujah</h1>
          <p className="text-xl md:text-2xl lg:text-3xl mt-2">A Fourth Year Game Development Project</p>
        </div>
      </header>
      <main>
        <section className="text-center p-5">
          <h2 className="text-2xl font-bold">My Fourth Year Capstone</h2>
          <p>This was the culmination of all my skills and experiences to date. I took on the role of Gameplay Lead,
            UI, and Game Programmer during our development cycle, which lasted two semesters.
          </p>

          <h3 className="text-xl font-semibold mt-4">Poster</h3>
          <img 
            src="/BallelujahPosterOrder.png" 
            alt="Game project poster" 
            className="mx-auto w-full md:w-1/2 lg:w-1/3" 
            loading="lazy"
          />
          <p className="mt-4">Game Descriptions</p>

          <h2 className="text-2xl font-bold mt-4">My Work</h2>
          <h3 className="text-xl font-semibold">Short Description</h3>
          <p>During this project, I implemented and worked on all in-game menus, some game effects, gameplay elements,
            and control functionalities.
          </p>

          <h3 className="text-xl font-semibold mt-4">Menus</h3>
          <img 
            src="/Ballelujah_Menu_Gif.gif" 
            alt="Game menu interactions" 
            className="mx-auto w-full md:w-1/2 lg:w-1/3" 
            loading="lazy"
          />
          <p className="mt-4">The GIF above showcases some of the work I implemented during my time on this development. I worked on setting up the camera for live title screen effects
            under the guidance of designers, and also collaborated with them to create functional character movements in scenes, button effects on movement, and placements
            of objects in scenes to achieve our overall design goals.
          </p>

          <h3 className="text-xl font-semibold mt-4">In Game</h3>
          <img 
            src="/Ballelujah_Game_Gif.gif" 
            alt="In-game action" 
            className="mx-auto w-full md:w-1/2 lg:w-1/3" 
            loading="lazy"
          />
          <p className="mt-4">I also designed scripts to implement character movements and positions, in-game effects of scores, power-ups, and other game effects.</p>
        </section>
      </main>
    </div>
  );
}

export default Ballelujah;
