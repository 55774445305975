import React from "react";

function BlogSection() {
  const posts = [
    { id: 1, title: "Blog Post 1", content: "Content of the first post..." },
    { id: 2, title: "Blog Post 2", content: "Content of the second post..." },
    // Add more posts as needed
  ];

  return (
    <div className="mt-8">
      <h1 className="text-center text-3xl font-semibold mb-8">Blog</h1>
      {posts.map(post => (
        <div key={post.id} className="blog mb-6 p-4 border-b border-gray-300">
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">{post.title}</h2>
          <p className="text-gray-600">{post.content}</p>
        </div>
      ))}
    </div>
  );
}

export default BlogSection;
