import React from "react";
import RecentProjectCard from "../components/RecentProjectCard";
import BlogSection from "../components/BlogSection";

function Workspace() {
  const recentProjects = [
    {
      id: 1,
      title: "Portfolio Website",
      description: "This is my main website, developed using the React framework.",
      imageUrl: "path-to-image-1.jpg",
    },
    {
      id: 2,
      title: "Endless Runner",
      description: "My first publishable game, created with Unity and available on iOS and Android.",
      imageUrl: "path-to-image-2.jpg",
    },
    {
      id: 3,
      title: "Project Three",
      description: "A brief description of my third project.",
      imageUrl: "path-to-image-3.jpg",
    },
  ];

  return (
    <div className="App text-center">
      <header className="relative flex flex-col justify-center items-center h-screen overflow-hidden">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
        >
          <source src="/Starfield Space 4K.mp4" type="video/mp4" />
        </video>

        <div className="relative z-2 text-white p-5 md:p-10">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">Workspace</h1>
          <p className="text-xl md:text-2xl lg:text-3xl mt-2">A showcase of my current projects and experimental codes.</p>
        </div>
      </header>

      <main>
        <section id="recent-projects" className="my-10 p-6 bg-white rounded-md shadow-md">
          <h2 className="text-2xl font-semibold mb-6">Recent Projects</h2>
          <div className="flex flex-wrap justify-center items-center gap-5">
            {recentProjects.map((project) => (
              <RecentProjectCard
                key={project.id}
                title={project.title}
                description={project.description}
                imageUrl={project.imageUrl}
              />
            ))}
          </div>
        </section>

        <section id="blog-posts" className="my-10 p-6 bg-white rounded-md shadow-md">
          <BlogSection />
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-3 text-center">
        <p>© {new Date().getFullYear()} Phillip Oiwoh. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Workspace;
