import React from 'react';

function RecentProjectCard({ title, description, imageUrl }) {
  return (
    <div className="border rounded-md shadow-md p-4 bg-white w-80">
      <img src={imageUrl} alt={title} className="w-full h-40 object-cover rounded-md mb-4" />
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-700 mb-4">{description}</p>
    </div>
  );
}

export default RecentProjectCard;
