import React from 'react';

function ProjectCard({ title, role, description, duration, technology, image, link }) {
  return (
    <div className="">
      <img src={image} alt={title} className="w-full h-45 object-cover rounded-md mb-4" />
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-600 mb-2">{role}</p>
      <p className="text-gray-700 mb-2">{description}</p>
      <p className="text-gray-500 mb-2">{duration}</p>
      <p className="text-gray-500 mb-4">{technology}</p>
      <a href={link} className="text-blue-500 hover:underline">Learn more</a>
    </div>
  );
}

export default ProjectCard;
