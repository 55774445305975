import React, { useState } from "react";
import ProjectCard from "../components/ProjectCard";

function HomePage() {
  const projects = [
    {
      title: "Through Their Eyes",
      role: "Producer/Developer",
      description: "A narrative-driven platformer game.",
      duration: "2018 - 2019",
      technology: "Godot, Photoshop",
      image: "/ThroughTheirEyeslogo.png",
      link: "/throughtheireyes",
    },
    {
      title: "Transferance",
      role: "Programmer",
      description: "A co-op puzzle game.",
      duration: "2019 - 2020",
      technology: "Unity",
      image: "/Transference_logo.png",
      link: "/transferance",
    },
    {
      title: "Unfamiliar",
      role: "Producer/Programmer/Designer",
      description: "A stealth adventure game.",
      duration: "2020 - 2021",
      technology: "Unity, Photoshop, Handsoft",
      image: "/Unfamiliar_Logo.png",
      link: "/unfamiliar",
    },
    {
      title: "Ballelujah",
      role: "Gameplay & UI Programmer",
      description: "A competitive multiplayer pinball game.",
      duration: "2021 - 2022",
      technology: "Unity, Handsoft",
      image: "/Ballelujahgame-logo.png",
      link: "/ballelujah",
    },
  ];

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ name, email, subject, message });
  };

  return (
    <div className="App text-center pt-16">
      <header className="relative flex flex-col justify-center items-center h-screen overflow-hidden">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
        >
          <source src="/Starfield Space 4K.mp4" type="video/mp4" />
        </video>

        <div className="relative z-2 text-white p-5 md:p-10">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">
            Phillip Oiwoh
          </h1>
          <h2 className="text-xl md:text-2xl lg:text-3xl mt-2">Portfolio</h2>
        </div>
      </header>
      <main className="pt-16">
        {/* Projects Section */}
        <section
          id="Projects"
          className="mb-12 p-8 bg-white rounded-md shadow-md"
        >
          <h1 className="text-5xl font-bold text-gray-800 mb-8 text-left">
            PROJECTS
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {projects.map((project) => (
              <div
                key={project.title}
                className="bg-gray-100 p-6 rounded-md shadow-md hover:shadow-lg transition-shadow"
              >
                <ProjectCard {...project} />
              </div>
            ))}
          </div>
        </section>

        {/* About Me Section */}
        <section
          id="AboutMe"
          className="mb-12 p-8 bg-white rounded-md shadow-md"
        >
          <h1 className="text-5xl font-bold text-gray-800 mb-8 text-left">
            ABOUT ME
          </h1>
          <div className="space-y-8 text-left">
            <div>
              <p className="text-lg text-gray-700 mb-5">
                My name is Phillip Oiwoh, a dedicated professional with a strong
                background in both web development and game development. I hold
                a Bachelor's Degree in Game Programming from Brock University
                and an Advanced Diploma in Game Development from Niagara
                College. I developed a robust skill set in programming and
                project management. I led 2 out of my 4 game projects during my
                university studies, with my first game gaining recognition and
                being featured in school promotions.
              </p>
            </div>

            {/* Web Development Section */}
            <div>
              <h3 className="text-2xl font-semibold text-gray-800">
                Web Development
              </h3>
              <p className="text-lg text-gray-700 mt-2">
                I am proficient in HTML, CSS, and JavaScript, with additional
                knowledge in React and Tailwind CSS. I have hands-on experience
                working on various web development projects, integrating complex
                functionalities and ensuring seamless user experiences. I am
                certified in React programming by W3.
              </p>
            </div>

            {/* Game Development Section */}
            <div>
              <h3 className="text-2xl font-semibold text-gray-800">
                Game Development
              </h3>
              <p className="text-lg text-gray-700 mt-2">
                My game development experience includes roles such as Producer,
                Developer, and Programmer. I have worked on narrative-driven
                games, stealth adventure games, and couch co-op games using
                technologies like Unity and Godot. My education and internships
                provided me with a solid foundation in game design and
                production.
              </p>
            </div>

            {/* Skills Section */}
            <div>
              <h3 className="text-2xl font-semibold text-gray-800">Skills</h3>
              <ul className="list-disc list-inside text-lg text-gray-700 mt-2 space-y-2">
                <li>
                  Proficient in programming languages such as C#, Python, and
                  React
                </li>
                <li>Knowledge of SQL, LaTeX, and various web technologies</li>
                <li>
                  Effective at identifying and solving technical challenges
                </li>
                <li>Experienced in leading and motivating teams</li>
                <li>Strong project management skills</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section
          id="Contact"
          className="mb-12 p-8 bg-white rounded-md shadow-md"
        >
          <h1 className="text-5xl font-bold text-gray-800 mb-8 text-left">
            Contact
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-100 p-8 rounded-md shadow-md">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Send A Message
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-4 border border-gray-300 rounded-md"
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-4 border border-gray-300 rounded-md"
                />
                <input
                  type="text"
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="w-full p-4 border border-gray-300 rounded-md"
                />
                <textarea
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full p-4 border border-gray-300 rounded-md"
                  rows="5"
                ></textarea>
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600 transition-colors"
                >
                  Send Message
                </button>
              </form>
            </div>
            <div className="bg-gray-100 p-8 rounded-md shadow-md">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Get in Touch
              </h2>
              <p className="text-lg text-gray-700 mb-6">
                Whether you want to get in touch, talk about a project
                collaboration, or just say hi, I'd love to hear from you. Simply
                fill the form and send me an email.
              </p>
              <div className="flex justify-center space-x-6">
                <a
                  href="mailto:phoiwoh@gmail.com"
                  className="hover:opacity-75 transition-opacity"
                >
                  <img src="/Mail_Logo.png" alt="Email" className="w-10 h-10" />
                </a>
                <a
                  href="https://linkedin.com/in/phillip-oiwoh-0b08827b"
                  className="hover:opacity-75 transition-opacity"
                >
                  <img
                    src="/linkedln_logo.png"
                    alt="LinkedIn"
                    className="w-10 h-10"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-gray-800 text-white py-3 text-center">
        <p>© {new Date().getFullYear()} Phillip Oiwoh. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;






