import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    setScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isHomePage = location.pathname === "/"; // Adjust according to your routing logic

  return (
    <nav className={`fixed top-0 w-full flex justify-end p-8 z-50 transition-colors duration-300 ${scrolled ? "bg-gray-700" : "bg-black"}`}>
      <ul className="list-none flex items-center p-0 m-0 space-x-4 md:space-x-6 lg:space-x-8">
        {isHomePage ? (
          <>
            <li>
              <a href="/workspace" className="text-white no-underline transition-colors duration-300 hover:bg-gray-200 hover:text-black rounded-md p-2">Workspace</a>
            </li>
          </>
        ) : (
          <>
            <li>
              <a href="/" className="text-white no-underline transition-colors duration-300 hover:bg-gray-200 hover:text-black rounded-md p-2">Home</a>
            </li>

          </>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
