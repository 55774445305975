import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import Workspace from './pages/Workspace';
import Ballelujah from './pages/GamePages/Ballelujah';
import ThroughTheirEyes from './pages/GamePages/ThroughTheirEyes';
import Unfamiliar from './pages/GamePages/Unfamiliar';
import Transferance from './pages/GamePages/Transferance';

import './index.css'; // Ensure Tailwind CSS is included

function App() {
  return (
    <Router>
      <Navbar />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/workspace" element={<Workspace />} />
          <Route path="/ballelujah" element={<Ballelujah />} />
          <Route path="/throughtheireyes" element={<ThroughTheirEyes />} />
          <Route path="/transferance" element={<Transferance />} />
          <Route path="/unfamiliar" element={<Unfamiliar />} />
        </Routes>

    </Router>
  );
}

export default App;
